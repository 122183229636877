import React, { useEffect, useState } from "react"
import {
  Badge,
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
// import { useState, useEffect} from "react/cjs/react.production.min";
import serveFunction from "serve/serve";

const Domains = () => {

  const [domains, setDomains] = useState([]);
  const [isload, setIsLoad] = useState(true);

  useEffect(() => {
    async function call() {
      let res = await serveFunction.listDomains()
      setDomains(res);
      setIsLoad(false)
    }
    call();
  }, [])

  if (isload) {
    return (<>
      <Container className="mt-5" fluid>
        {/* Table */}
        <Row className="row justify-content-md-center">
          <div className="col">
            <center>
              <div style={{ marginTop: 250, marginBottom: 300 }} className="spinner-grow text-primary" role="status">
                <span className="sr-only align-bottom">Loading...</span>
              </div>
            </center>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>);
  }

  function colorButton(params) {
    switch (params) {
      case (0):{
        return(
        <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-default" />
            No Iniciado
          </Badge>
        </>);
      }
      case (1):{
        return(
        <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />
            Pendiente
          </Badge>
        </>);
      }
      case (2):{
        return(
          <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-danger" />
            Rechazado
          </Badge>
        </>);
      }
      case (3):{
        return(
        <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-info" />
            En análisis
          </Badge>
        </>);
      }
      case (4):{
        return(
        <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-success" />
            Aprobado
          </Badge>
        </>);
      }
      default:{
        <>
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-info" />
          ...
        </Badge>
      </>
      }
    }
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Dominios</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Dominios</th>
                    <th scope="col">Status</th>
                    {/* <th scope="col">Status</th> */}
                    {/* <th scope="col">Completion</th> */}
                    {/* <th scope="col" /> */}
                  </tr>
                </thead>
                <tbody>
                  {domains.map((item) => (
                    <tr key={item}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {item.id_domain}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{item.name}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          {/* {item.status} */}
                          {colorButton(item.id_domain_status)}
                        </Badge>
                      </td>
                      {/* <td>
                        <Badge color="" className="badge-dot">
                          {colorButton(item.status_checklist)}
                        </Badge>
                      </td> */}
                      {/* <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#"
                              onClick={(e) => e.preventDefault()}
                            >
                              Información
                            </DropdownItem>
                            <DropdownItem
                              href="#"
                              onClick={(e) => e.preventDefault()}
                            >
                              Bloques
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>
  );
};

export default Domains;
