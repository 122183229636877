import React, { useEffect, useState } from "react"
import Chart from "chart.js";


import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Media,
  Badge
} from "reactstrap";

// core components
import {chartOptions,parseOptions} from "variables/charts.js";

import HeaderHome from "components/Headers/HeaderHome.js";
import serveFunction from "serve/serve";

const Index = () => {

  /* const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState('data1'); */

  const [domains, setDomains] = useState([]);
  const [isload, setIsLoad] = useState(true);
  const [dataUser, setDataUser] = useState([]);
  const [listRevShare, setListRevShare] = useState({});
  const [stateFooter, setStateFooter] = useState(false);
  const [valuesTotal, setValuesTotal] = useState([]);

  useEffect(() => {
    async function call() {
      let res = await serveFunction.listDomains();
      let usersBank = await serveFunction.dataUser();
      setDataUser(usersBank.data);
      setDomains(res);
      setIsLoad(false);
      if(dataUser.id === '8416'){
        revShare();
      }
    }
    call();
  }, [dataUser.id])

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  /* const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  }; */

  if (isload) {
    return (<>
      <Container className="mt-5" fluid>
        {/* Table */}
        <Row className="row justify-content-md-center">
          <div className="col">
            <center>
              <div style={{ marginTop: 250, marginBottom: 300 }} className="spinner-grow text-primary" role="status">
                <span className="sr-only align-bottom">Loading...</span>
              </div>
            </center>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>);
  }

  function colorButton(params) {
    switch (params) {
      case (0):{
        return(
        <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-default" />
            No Iniciado
          </Badge>
        </>);
      }
      case (1):{
        return(
        <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />
            Pendiente
          </Badge>
        </>);
      }
      case (2):{
        return(
          <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-danger" />
            Rechazado
          </Badge>
        </>);
      }
      case (3):{
        return(
        <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-info" />
            En análisis
          </Badge>
        </>);
      }
      case (4):{
        return(
        <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-success" />
            Aprobado
          </Badge>
        </>);
      }
      default :{
        return(
        <>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-info" />
            ...
          </Badge>
        </>);
      }
    }
    
  }

  async function revShare() {
      let res = await serveFunction.revShare()
      let data = await res.data;
      setListRevShare(data);
      setStateFooter(true);

      var total = data.reduce(getTotal, 0);
      function getTotal(total, item) {
        return total + (item.impressions);
      }

      var totalRevenue = data.reduce(getTotalRevenue, 0);
      function getTotalRevenue(total, item) {
        return total + (item.revenue);
      }

      var totalRevShare = data.reduce(getTotalRevShare, 0);
      function getTotalRevShare(total, item) {
        return total + (item.rev_daniel);
      }
      setValuesTotal({
        impressions: total,
        revenue: totalRevenue.toFixed(2),
        revShare: totalRevShare.toFixed(2)
      });

      return data;
  }
  
  return (
    <>
      <HeaderHome />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          {/* {listDomOrRev()} */}
          {stateFooter === false ? <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Sitios en analisis</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="/admin/domains"
                      size="sm"
                    >
                      Ver todos
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Dominios</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                {domains.map((item) => ( item.id_domain_status !== 4 && 
                    <tr key={item}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {item.id_domain}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{item.name}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          {/* {item.status} */}
                          {colorButton(item.id_domain_status)}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>: <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Sitios en analisis</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="/admin/domains"
                      size="sm"
                    >
                      Ver todos
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Dominios</th>
                    <th scope="col">Impresione</th>
                    <th scope="col">Ingreso</th>
                    <th scope="col">RevShare</th>
                  </tr>
                </thead>
                <tbody>
                {listRevShare.map((item) => (
                    <tr key={item}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {item.domain}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{item.impressions}</td>
                      <td>$ {item.revenue.toFixed(2)}</td>
                      <td>
                        $ {item.rev_daniel.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              Todos
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{valuesTotal.impressions}</td>
                      <td>$ {valuesTotal.revenue}</td>
                      <td>$ {valuesTotal.revShare}</td>
                    </tr>
                </tbody>
              </Table>
            </Card>
          </Col>}
        </Row>
      </Container>
    </>
  );
};
export default Index;
