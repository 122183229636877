import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import serveFunction from "serve/serve";

const AdminNavbar = (props) => {

  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function call() {
      let usersBank = await serveFunction.dataUser();
      setUsers(usersBank.data);
    }
    call();
  }, [])

  if (users.length === 0) {
    return <></>
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/perfil-renovads.png")
                          .default
                      }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {users.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bienvenido!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/bank-data" tag={Link}>
                  {/* <DropdownItem onClick={dataUser}> */}
                  <i className="ni ni-single-02" />
                  <span>Perfil</span>
                </DropdownItem>
                <DropdownItem to="/admin/suport" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Suporte</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => {
                  localStorage.removeItem('auth-token-access')
                  localStorage.removeItem('auth-token-refresh')
                }} tag={Link}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
                {/* <DropdownItem href="/auth/login" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
