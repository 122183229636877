
import React, { useCallback, useEffect, useState } from "react"
import serveFunction from "serve/serve";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const Header = () => {

  const [isload, setIsLoad] = useState(true);
  //const [dataReports, setDataReports] = useState({});
  const [dataViews, setDataViews] = useState({});
  
  

  const getValues = useCallback(async () =>{


    var dateCustom = new Date();
    var dia = String(dateCustom.getDate()).padStart(2, '0');
    var mes = String(dateCustom.getMonth() + 1).padStart(2, '0');
    var ano = dateCustom.getFullYear();
    dateCustom = ano + '-' + mes + '-' + dia;

    
    let dat = {
      dataInitial: ano + '-' + mes + '-' + '1',
      dataFinal: dateCustom
    }


    let dataReports = await serveFunction.listReports(dat);
    //setDataReports(dataReports);
    setIsLoad(false);
    values(dataReports);

  }, [])


  useEffect(() => {
    getValues();
  }, [getValues])


  

  function values(params) {
    var totalImpressions = params.reduce(getTotalImpressions, 0);
    var totalRevenue = params.reduce(getTotalRevenue, 0);
    var totalEcpm = params.reduce(getTotalEcpm, 0);
    var totalView = params.reduce(getTotalView, 0);

    function getTotalImpressions(total, item) {
      return total + (item.impressions);
    }
    function getTotalRevenue(total, item) {
      return total + (item.revenue);
    }
    function getTotalEcpm(total, item) {
      return total + (item.ecpm);
    }
    function getTotalView(total, item) {  
      return total + (item.view_ability); }

    totalEcpm = totalEcpm / params.length;
    // totalView = totalView / params.length;
    totalView = (totalView/totalImpressions);

    setDataViews({
      impressions: totalImpressions.toLocaleString('br'),
      revenue: totalRevenue.toFixed(2),
      ecpm: totalEcpm.toFixed(2),
      view_ability: totalView.toFixed(2),
    });
  }

  if (isload) {
    return (<>
      <Container className="mt-5" fluid>
        {/* Table */}
        <Row className="row justify-content-md-center">
          <div className="col">
            <center>
              <div style={{ marginTop: 250, marginBottom: 300 }} className="spinner-grow text-primary" role="status">
                <span className="sr-only align-bottom">Loading...</span>
              </div>
            </center>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>);
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Impresiones
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {dataViews.impressions}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">Impresiones mensuales</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Ingresos
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">$ {dataViews.revenue}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">Ingresos mensuales</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3" className="mb-2">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          eCPM
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">$ {dataViews.ecpm}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">eCPM promedio actual</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Active View
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{dataViews.view_ability}%</span>
                      </div>
                      <Col className="col-auto">
                      <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                      <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">Active View actual</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
