import { env } from "App/config";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

const Login = () => {

  const [login, setLogin] = useState({
    email:"",
    password:""
  });
  const [message, setMessage] = useState({ errorMessage: "", successMessage: "" });
  const [loadButton, setLoadButton] = useState(false);
  const [credentials, setCredentials] = useState(true);

  const endpoint = env.END_POINT_API;
  
  const onchange = (e) => {
    const { value, name } = e.target
    setLogin({ ...login, [name]: value })
  }

  const eventLogin = useCallback(async (event)=>{
    event.preventDefault();
    setLoadButton(true);
    let config = {
      headers: {
        "Content-Type": "application/json",
      }
    }

    let data = {
      "email": login.email,
      "password": login.password
    }
    
    await axios.post(
      `${endpoint}users/auth`,
      data, config)
      .then( r => {
        let resp = r.data;
        if(resp.status){
          const token_list = resp.user.token;
          localStorage.setItem('auth-token-access', token_list);
          localStorage.setItem('auth-token-refresh', token_list);
          setMessage({ errorMessage: "", successMessage: "" });
          //setLogin(r.results[0].token_user);
        }else{
          setMessage({errorMessage: "Credenciales erroneas"})
        }
        setLoadButton(false);
      }).catch(error => {
         setLoadButton(false);
        setCredentials(false);
        setMessage({ errorMessage: error.message });
        console.log("error: ", error); 
        console.log(error) 
        return false;
      });  
  }
,[login,endpoint])
  
  if (localStorage.getItem('auth-token-access') || localStorage.getItem('auth-token-refresh')) {
    return <Redirect to='/admin/index' />;
  }

  function buttonLoad() {
    if (loadButton) {
      return (
        <div className="col">
            <div style={{ marginTop: 25 }} className="spinner-grow text-primary" role="status">
              <span className="sr-only align-bottom">Loading...</span>
            </div>
        </div>
      );
    } else {
      return (
        <>
          <Button onClick={eventLogin} className="my-4" color="primary" type="button">
            Iniciar Sesión
          </Button>
        </>
      );
    }
  }

  function credentialsLoad() {
    if (credentials) {
      return (
        <small>Iniciar sesión con credenciales</small>
        );
    } else {
      return (
        <>
        <small style={{ color: '#ff0000' }} > {message}</small>
        </>
      );
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              {/* <small>Iniciar sesión con credenciales</small>
               */}
               {credentialsLoad()}
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={onchange}
                    name="email"
                    value={login.email}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Contraseña"
                    type="password"
                    autoComplete="new-password"
                    onChange={onchange}
                    name="password"
                    value={login.password}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Acuérdate de mí</span>
                </label>
              </div>
              <div className="text-center">
                {/* <Button onClick={eventLogin} className="my-4" color="primary" type="button">
                  Iniciar Sesión
                </Button> */}
                {
                  buttonLoad()
                }
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="/auth/forgot-password"
            // onClick={(e) => e.preventDefault()}
            >
              <small>Olvidé mi contraseña</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="/auth/register"
            // onClick={(e) => e.preventDefault()}
            >
              <small>Crear nueva cuenta</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
