import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import serveFunction from "serve/serve";

const HeaderAccount = () => {

  //const [listHistoryRevenue, setListHistoryRevenue] = useState({});
  const [isload, setIsLoad] = useState(true);
  const [totalValue, setTotalValue] = useState({});

  useEffect(() => {
    var dat = dataHoje();

    async function call() {
      let res = await serveFunction.listHistory()
      let resDeductions = await serveFunction.listDeductions()
      let dataReports = await serveFunction.listReports(dat);
      //setListHistoryRevenue(res);
      setIsLoad(false)

      function getTotalRevenue(total, item) {
        return total + (item.revenue);
      }
      
      function getTotal(total, item) {
        return total + (item.value);
      }
      
      var totalRevenue = dataReports.reduce(getTotalRevenue, 0);
      var total = res.reduce(getTotal, 0);
      var totalDeductions = resDeductions.reduce(getTotal, 0);

      setTotalValue({retirado: total.toFixed(2), invalido: totalDeductions.toFixed(2), saldo: totalRevenue.toFixed(2)})
    }
    call();
  }, []);

  function dataHoje(params) {
    var dateCustom = new Date();
    var dia = String(dateCustom.getDate()).padStart(2, '0');
    var mes = String(dateCustom.getMonth() + 1).padStart(2, '0');
    var ano = dateCustom.getFullYear();
    dateCustom = ano + '-' + mes + '-' + dia;

    var dat = {
      dataInitial: ano + '-' + mes + '-' + '1',
      dataFinal: dateCustom
    }
    return dat;
  }

  if (isload) {
    return (<>
      <Container className="mt-5" fluid>
        {/* Table */}
        <Row className="row justify-content-md-center">
          <div className="col">
            <center>
              <div style={{ marginTop: 250, marginBottom: 300 }} className="spinner-grow text-primary" role="status">
                <span className="sr-only align-bottom">Loading...</span>
              </div>
            </center>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>);
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              {/* <Col lg="6" xl="6" className="mb-3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          INGRESSO
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          $1.897,00
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Retirar
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">$ {totalValue.retirado}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i class="fas fa-hand-holding-usd"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" className="mb-2"> 
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          INVÁLIDOS
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">$ {totalValue.invalido}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="12" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Saldo de mes
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">$ {totalValue.saldo}</span>
                      </div>
                      <Col className="col-auto">
                      <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                      <i class="fas fa-money-bill-wave"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeaderAccount;
