
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import  {isAuthenticated}  from "./auth";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/auth/login", state: { from: props.location } }} />
        )
      }
    />
  );

  

function App() {
    return (
        <BrowserRouter>
        <Switch>
          <Route path="/auth" component={(props) => <AuthLayout {...props} />} />
          <PrivateRoute path="/admin" component={(props) => <AdminLayout {...props} />} />
          <Redirect from="/" to="/admin/index" />
        </Switch>
        </BrowserRouter>
    );
}

export default App;