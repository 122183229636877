import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import UserHeader from "components/Headers/UserHeader.js";
import serveFunction from "serve/serve";

const BankData = () => {

  const [dataUser, setDataUser] = useState([]);

  // const [users, setUsers] = useState([]);

  useEffect(() => {
    async function call() {
      let usersBank = await serveFunction.dataUser();
      setDataUser(usersBank.data);
      console.log(usersBank.data);
    }
    call();
  }, [])

  const list = [
    { id: 1, name: 'Nacionalidad' },
    { id: 2, name: 'Argentina' },
    { id: 3, name: 'Chile' },
    { id: 4, name: 'Brazil' },
    { id: 5, name: 'Paraguay' },
    { id: 6, name: 'Uruguay' },
  ];

  if (dataUser.length === 0) {
    return (<>
      <Container className="mt-5" fluid>
        {/* Table */}
        <Row className="row justify-content-md-center">
          <div className="col">
            <center>
              <div style={{ marginTop: 250, marginBottom: 300 }} className="spinner-grow text-primary" role="status">
                <span className="sr-only align-bottom">Loading...</span>
              </div>
            </center>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>);
  }

  async function saveDataUser() {
    let usersBank = await serveFunction.putUser(dataUser);
    await usersBank;
  }

  const onchange = (e) => {
    const { value, name } = e.target
    setDataUser({ ...dataUser, [name]: value })
  }

  function personalData() {
    if (dataUser.nacionalidade === 'Argentina') {
      return (<>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              Documento Nacional de Identidad (DNI)
            </label>
            <Input
              className="form-control-alternative"
              id="input-dni"
              onChange={onchange}
              name="dni"
              value={dataUser.CPF_CNPJ}
              placeholder="Escribe tu DNI"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              Registro Único Tributario (RUT)
            </label>
            <Input
              className="form-control-alternative"
              id="input-rut"
              onChange={onchange}
              name="PIS"
              value={dataUser.PIS}
              placeholder="Escribe tu RUT"
            />
          </FormGroup>
        </Col>
      </>);
    }
    if (dataUser.nacionalidade === 'Chile') {
      return (<>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              Cédula de Indentidad (C.I)
            </label>
            <Input
              className="form-control-alternative"
              id="input-ci"
              onChange={onchange}
              name="CPF_CNPJ"
              value={dataUser.CPF_CNPJ}
              placeholder="Escribe tu CI"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              Rol Único Tributario (RUT)
            </label>
            <Input
              className="form-control-alternative"
              id="input-ruc"
              onChange={onchange}
              name="PIS"
              value={dataUser.PIS}
              placeholder="Escribe tu RUT"
            />
          </FormGroup>
        </Col>
      </>);
    }
    if (dataUser.nacionalidade === 'Brazil') {
      return (<>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              CPF
            </label>
            <Input
              className="form-control-alternative"
              id="input-CPF_CNPJ"
              onChange={onchange}
              name="CPF_CNPJ"
              value={dataUser.CPF_CNPJ}
              placeholder="Escribe tu CPF"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              PIX
            </label>
            <Input
              className="form-control-alternative"
              id="input-pix"
              onChange={onchange}
              name="pix"
              value={dataUser.pix}
              placeholder="Escribe tu PIX"
              type="text"
            />
          </FormGroup>
        </Col>
      </>);
    }
    if(dataUser.nacionalidade === 'Paraguay'){
      return (<>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              Cédula de Indentidad (C.I)
            </label>
            <Input
              className="form-control-alternative"
              id="input-ci"
              onChange={onchange}
              name="CPF_CNPJ"
              value={dataUser.CPF_CNPJ}
              placeholder="Escribe tu CI"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              Registro Único del Contribuyente (RUC)
            </label>
            <Input
              className="form-control-alternative"
              id="input-ruc"
              onChange={onchange}
              name="PIS"
              value={dataUser.PIS}
              placeholder="Escribe tu RUC"
            />
          </FormGroup>
        </Col>
      </>);
    }
    if(dataUser.nacionalidade === 'Uruguay'){
      return (<>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              Cédula de Identidad (CI)
            </label>
            <Input
              className="form-control-alternative"
              id="input-ci"
              onChange={onchange}
              name="CPF_CNPJ"
              value={dataUser.CPF_CNPJ}
              placeholder="Escribe tu CI"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-address"
            >
              Registro Único Tributario (RUT)
            </label>
            <Input
              className="form-control-alternative"
              id="input-rut"
              onChange={onchange}
              name="PIS"
              value={dataUser.PIS}
              placeholder="Escribe tu RUT"
            />
          </FormGroup>
        </Col>
      </>);
    }
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">Datos Bancarios</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    INFORMACION DEL USUARIO
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nombre de usuario
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            onChange={onchange}
                            name="name"
                            value={dataUser.name}
                            placeholder="Nombre de usuario"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            onChange={onchange}
                            name="email"
                            value={dataUser.email}
                            placeholder="contacto@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-passord"
                          >
                            Contraseña
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-password"
                            onChange={onchange}
                            name="password"
                            value={dataUser.password}
                            placeholder="Contraseña"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nacionalidade"
                          >
                            Nacionalidad
                          </label>
                          <div className="col">
                            <select name="nacionalidade" class="form-control" onChange={
                              (e) => {
                                setDataUser({ ...dataUser, nacionalidade: e.target.value })
                              }
                            }>
                              {list.map((item) => (
                                <option
                                  value={dataUser.nacionalidade}>{item.name}</option>
                              ))}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-whatsapp"
                          >
                            WhatsApp
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-whatsapp"
                            onChange={onchange}
                            name="whatsapp"
                            value={dataUser.whatsapp}
                            placeholder="+DDI Número"
                            type="tel"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {
                        personalData()
                      }
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Dirección
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-endereco"
                          >
                            Dirección
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-endereco"
                            onChange={onchange}
                            name="endereco"
                            value={dataUser.endereco}
                            placeholder="Escribe tu Dirección"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-reference"
                          >
                            Referencia
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-reference"
                            onChange={onchange}
                            name="bairro"
                            value={dataUser.bairro}
                            placeholder="Escribe tu referencia"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cidade"
                          >
                            Ciudad
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-cidade"
                            onChange={onchange}
                            name="cidade"
                            value={dataUser.cidade}
                            placeholder="Escribe tu Ciudad"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            País
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-country"
                            onChange={onchange}
                            name="nacionalidade"
                            value={dataUser.nacionalidade}
                            placeholder="Escribe tu País"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cep"
                          >
                            Código Postal
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-cep"
                            onChange={onchange}
                            name="cep"
                            value={dataUser.cep}
                            placeholder="Postal code"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">Datos Bancarios</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-swift"
                          >
                            SWIFT
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-swift"
                            onChange={onchange}
                            name="swift"
                            value={dataUser.swift}
                            placeholder="Escribe tu SWIFT "
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-iban"
                          >
                            IBAN
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-iban"
                            onChange={onchange}
                            name="iban"
                            value={dataUser.iban}
                            placeholder="Escribe tu IBAN"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <center>
                      <Button onClick={() => { saveDataUser() }} color="primary" type="button">
                        Guardar
                      </Button>
                    </center>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BankData;
