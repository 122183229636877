import Index from "views/Index.js";
import Domains from "./views/Domains";
import Reports from "./views/Reports";
import Suport from "./views/Suport";
import BankData from "./views/BankData";

import CheckingAccount from "./views/CheckingAccount";

import Login from "./views/Login.js";
import Register from "./views/Register.js";
import invalid from "./views/Invalid";
import ForgotPassword from './views/ForgotPassword'
import InfoDomain from './views/InfoDomain';

import Ravshare from './views/Ravshare';


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reportes",
    icon: "ni ni-chart-bar-32 text-blue",
    component: Reports,
    layout: "/admin",
  },
  // {
  //   path: "/url-builder",
  //   name: "Url Builder",
  //   icon: "ni ni-bullet-list-67 text-info",
  //   component: UrlBuilder,
  //   layout: "/admin",
  // },
  {
    path: "/domains",
    name: "Dominios",
    icon: "ni ni ni-world-2 text-red",
    component: Domains,
    layout: "/admin",
  },
  {
    path: "/suport",
    name: "Soporte",
    icon: "ni ni ni-chat-round text-yellow",
    component: Suport,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/bank-data",
    name: "Datos Bancarios",
    icon: "ni ni-money-coins text-pink",
    component: BankData,
    layout: "/admin",
  },
  // {
  //   path: "/historic",
  //   name: "Histórico",
  //   icon: "ni ni-chart-pie-35 text-blue",
  //   component: Historic,
  //   layout: "/admin",
  // },
  // {
  //   path: "/invoicing",
  //   name: "Facturación",
  //   icon: "ni ni-chart-bar-32 text-orange",
  //   component: Invoicing,
  //   layout: "/admin",
  // },
  {
    path: "/checking-account",
    name: "Extracto",
    icon: "ni ni-credit-card text-blue",
    component: CheckingAccount,
    layout: "/admin",
  },
  // {
  //   path: "/contracts",
  //   name: "Contrato",
  //   icon: "ni ni-paper-diploma text-red",
  //   component: Contracts,
  //   layout: "/admin",
  // },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    icon: "ni ni-fat-remove text-yellow",
    component: ForgotPassword,
    layout: "/auth",
  },  
  {
    path: "/invalid",
    name: "Deducciones",
    icon: "ni ni-fat-remove text-yellow",
    component: invalid,
    layout: "/admin",
  },
  {
    path: "/info-domain",
    name: "Domain Info",
    // icon: "ni ni-fat-remove text-yellow",
    component: InfoDomain,
    layout: "/admin",
  },
  // {
  //   path: "#",
  //   name: "Capsula",
  //   icon: "ni ni-chart-bar-32 text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-chart-bar-32 text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Tables",
  //   icon: "ni ni ni-world-2 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "Profile",
  //   icon: "ni ni ni-chat-round text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Dominios",
  //   icon: "ni ni ni-world-2 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
];
if(localStorage.getItem('auth-token-access')){
let userx = decodeURIComponent(escape(window.atob(localStorage.getItem('auth-token-access'))));
    userx = JSON.parse(userx);
    if(userx.email === "ovospace0309@gmail.com"){
      routes.push({
        path: "/ravshare",
        name: "Comisiones",
        icon: "ni ni-money-coins text-green",
        component: Ravshare,
        layout: "/admin",
      })
    }
  }

export default routes;
