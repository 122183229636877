
import React, { useCallback, useEffect, useState } from "react"
import serveFunction from "serve/serve";
import { Card, CardBody, CardTitle, Container, Row, Col, Media } from "reactstrap";

const Header = () => {

  const [isload, setIsLoad] = useState(true);
  //const [dataReports, setDataReports] = useState({});
  const [dataViews, setDataViews] = useState({});

  

  const getValues = useCallback(async () => {

    let dateCustom = new Date();
    let dia = String(dateCustom.getDate()).padStart(2, '0');
    let mes = String(dateCustom.getMonth() + 1).padStart(2, '0');
    let ano = dateCustom.getFullYear();

    let dateCustom2 = ano + '-' + mes + '-' + dia;
    var dat = {
      dataInitial: ano + '-' + mes + '-' + '1',
      dataFinal: dateCustom2
    }

    let dataReports = await serveFunction.listReports(dat);
    //setDataReports(dataReports);
    setIsLoad(false);
    values(dataReports);

  }, [])


  useEffect(() => {
    getValues();
  }, [getValues])



  function values(params) {
    var totalImpressions = params.reduce(getTotalImpressions, 0);
    var totalRevenue = params.reduce(getTotalRevenue, 0);
    var totalEcpm = params.reduce(getTotalEcpm, 0);
    var totalView = params.reduce(getTotalView, 0);

    function getTotalImpressions(total, item) {
      return total + (item.impressions);
    }
    function getTotalRevenue(total, item) {
      return total + (item.revenue);
    }
    function getTotalEcpm(total, item) {
      return total + (item.ecpm);
    }
    function getTotalView(total, item) {
      // console.debug('TEST: ',item.view_ability)
      return total + (item.view_ability);
    }
    // console.debug('VIEW ABILITY: ', totalView)
    // console.debug('Impressions: ', totalImpressions)
    // console.debug('PARAMS: ', params)
    
    totalEcpm = totalEcpm / params.length;
    
    totalView = (totalView/totalImpressions);
    // totalView = totalView / params.length;
    //let media = totalView / params.length;

    setDataViews({
      impressions: totalImpressions.toLocaleString('br'),
      revenue: totalRevenue.toFixed(2),
      ecpm: totalEcpm.toFixed(2),
      view_ability: totalView.toFixed(2),
    });
  }

  if (isload) {
    return (<>
      <Container className="mt-5" fluid>
        {/* Table */}
        <Row className="row justify-content-md-center">
          <div className="col">
            <center>
              <div style={{ marginTop: 250, marginBottom: 300 }} className="spinner-grow text-primary" role="status">
                <span className="sr-only align-bottom">Loading...</span>
              </div>
            </center>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>);
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>

              <Col lg='10' xl='10'>
                <Row>
                  <Col lg="4" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Impresiones
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {dataViews.impressions}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">Impresiones mensuales</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Ingressos
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">$ {dataViews.revenue}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-chart-pie" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">Ingressos mensuales</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4" xl="4" className="mb-2">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              eCPM
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">$ {dataViews.ecpm}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="ni ni-money-coins" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">eCPM promedio actual</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="6">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                             View Ability
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{dataViews.view_ability}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="fas fa-percent" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">View Ability actual</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="6">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Status MCM
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">Aprobado</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                              <i class="fas fa-business-time"></i>
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">Multiple Customer Management</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg='2' xl='2'>
                <Col >
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col lg="12" md="12">
                          <Media className="align-items-center">
                              <img
                              className='rounded'
                                alt="..."
                                width="100%"
                                src={
                                  require("../../assets/img/theme/daniel-abegg.jpeg")
                                    .default
                                }
                              />
                          </Media>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="mt-3 mb-0 text-muted text-sm"><center>
                            <span className="text-nowrap"><b>Daniel Abegg</b></span><br />
                            <span className="text-nowrap">Gerente</span></center>
                          </p>
                        
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Col>
            </Row>
            {/* Card stats */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
