import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

const InfoDomain = () => {

  const [sendSuport, SetSendSuport] = useState([]);

  const listCategory = [
    "Deporte",
    "Gastronomía",
    "Noticias en general",
    "Finanzas",
    "Otros",
    "Alimentos",
    "Aplicaciones móviles",
    "Arte",
    "Belleza y cuidado",
    "Automóviles",
    "Casa y jardin",
    "Ordenadores",
    "Trabajos",
    "Educación",
    "familia y comunidad",
    "aficiones",
    "Juegos online",
    "Propiedades",
    "Internet y Telefonía",
    "Ley y Gobierno",
    "Negocios e Industria",
    "Relación",
    "Salud",
    "Ropa",
    "Viaje y Turismo"
  ];

  const onchange = (e) => {
    const { value, name } = e.target
    SetSendSuport({ ...sendSuport, [name]: value })
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">Soporte</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Input
                            disabled
                            className="form-control-alternative"
                            id="input-subject"
                            onChange={onchange}
                            name="subject"
                            value={sendSuport.subject}
                            placeholder="Asunto"
                            type="text"
                          />

                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-subject"
                          >
                            Asunto
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-subject"
                            onChange={onchange}
                            name="pageviews"
                            value={sendSuport.pageviews}
                            placeholder="PageViews"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-sector"
                          >
                            Sección
                          </label>
                          <select name="id" onChange={onchange} class="form-control">
                          {
                            listCategory.map((item,i) => (
                              <option key={i} value={item}>{item}</option>
                            ))
                          }
                          {/* <option selected>Filtrar</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option> */}
                        </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {/* Description */}
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-subject"
                          >
                            URL del Login
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-subject"
                            onChange={onchange}
                            name="url_login"
                            value={sendSuport.url_login}
                            placeholder="URL del Login"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-subject"
                          >
                            Login
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-subject"
                            onChange={onchange}
                            name="login"
                            value={sendSuport.login}
                            placeholder="Login"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-subject"
                          >
                            Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-subject"
                            onChange={onchange}
                            name="password"
                            value={sendSuport.password}
                            placeholder="Password"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InfoDomain;
