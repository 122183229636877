// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import serveFunction from "serve/serve";

const Suport = () => {

  const [sendSuport, setSendSuport] = useState(
    { "id_ticket_status": 1, "id_priority": 1 }
  );
  const [repSup, setRepSup] = useState({});
  const [replySuport, setReplySuport] = useState(false);
  const [retListreplys, setRetListreplys] = useState([]);
  const [listSupport, setListSupport] = useState([{}]);
  const [domains, setDomains] = useState([]);
  const [isload, setIsLoad] = useState(true);

  const onchange = (e) => {
    const { value, name } = e.target
    setSendSuport({ ...sendSuport, [name]: value })
  }

  function formSupport(params) {
    if (retListreplys === "") {
      return (<>
        <Card className="bg-secondary shadow mb-4">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="7">
                <h3 className="mb-0">Soporte</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-subject"
                      >
                        Asunto
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-subject"
                        onChange={onchange}
                        name="subject"
                        value={sendSuport.subject}
                        placeholder="Asunto"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-sector"
                      >
                        Sección
                      </label>
                      <select onChange={onchange} name="id_department" class="form-control" id="selectSector">
                        <option value={1} >Asistencia</option>
                        <option value={2} >Desarrollo</option>
                        <option value={3} >Financiero</option>
                        <option value={3}>Junta Directiva</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-sector"
                      >
                        Dominio
                      </label>
                      <select name="id_domain" class="form-control" onChange={onchange}>
                        {domains.map((item) => (
                          <option
                            value={item.id_domain}>{item.name}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              {/* Description */}
              <div className="pl-lg-4">
                <FormGroup>
                  <label>Describe el problema</label>
                  <Input
                    className="form-control-alternative"
                    onChange={onchange}
                    name="description"
                    value={sendSuport.description}
                    placeholder="Escribe lo que necesitas..."
                    rows="4"
                    type="textarea"
                  />
                </FormGroup>
              </div>
              <div className="pl-lg-4">
                <center>
                  <Button onClick={() => { addTicketFun() }} color="primary" type="button">
                    Guardar
                  </Button>
                </center>
              </div>
            </Form>
          </CardBody>
        </Card>
      </>);
    }
  }

  useEffect(() => {
    async function call() {
      let res = await serveFunction.listSupport()
      let resDomain = await serveFunction.listDomains()
      setListSupport(res);
      setDomains(resDomain);
      setIsLoad(false)
    }
    call();
  }, [])

  async function addTicketFun() {
    let funAddTicket = await serveFunction.addTicket(sendSuport)
    if (funAddTicket.status === 200) {
      setSendSuport({ description: "",
      id_department: "",
      id_domain: "",
      id_priority: 1,
      id_ticket_status: 1,
      subject: ""})
    }
  }

  async function addReplyTicketFun(){
    let funAddTicket = await serveFunction.addReplyTicket(repSup)
    let data = await funAddTicket;
    console.log(data);
    setRepSup({response:""});
  }  

  async function funlistReply(params) {
    let listReplys = await serveFunction.listReplySupport(params);
    setReplySuport(!replySuport)
    setRetListreplys(listReplys);
    return listReplys;
  }

  /* function contTickers(params) {
    var cont = 0;
    for (let i = 0; i in listSupport; i++) {
      if (listSupport[i].id_department == 1) {
        cont++;
        console.log('sim');
      } else {
        console.log('nao');
      }
    }
    console.log('cont: ', cont);
    cont = 0;
  } */

  if (isload) {
    return (<>
      <Container className="mt-5" fluid>
        {/* Table */}
        <Row className="row justify-content-md-center">
          <div className="col">
            <center>
              <div style={{ marginTop: 250, marginBottom: 300 }} className="spinner-grow text-primary" role="status">
                <span className="sr-only align-bottom">Loading...</span>
              </div>
            </center>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>);
  }

  function sendReplySuport(params) {
    if (retListreplys !== "") {
      return (
        <>
          <Card className="card-profile shadow">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col xs="7">
                    <h3 className="mb-0">Interacciones</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  {
                    retListreplys.map((item, index) => (
                      <Row key={index}>
                        <Col lg="auto">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-subject"
                              onChange={onchange}
                              name="subject"
                              value={item.response}
                              // placeholder="Asunto"
                              disabled
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ))
                  }
                  {/* Description */}
                  <Row className="justify-content-center">
                    <Col lg="9">
                      <Input
                        className="form-control-alternative"
                        onChange={(e) => {setRepSup({...repSup,response: e.target.value, id_ticket: retListreplys[0].id_ticket, type:2})}}
                        name="response"
                        placeholder="Escribe lo que necesitas..."
                        value={repSup.response}
                        rows="1"
                        type="textarea"
                      />
                    </Col>
                    <Col lg="3">
                      <center>
                        <Button onClick={() => { addReplyTicketFun() }} color="primary" type="button">
                          Enviar
                        </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Card>
        </>
      );
    }
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 xl-0 mb-2" xl="5">
            <Card className="card-profile shadow">
              <CardBody className="pt-0 pt-md-1">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center">
                      <div>
                        {/* <span className="heading">1</span> */}
                        <span className="description">Tus Tickets</span>
                      </div>
                      {/* <div>
                        <span className="heading">0</span>
                        <span className="description">En Proceso</span>
                      </div>
                      <div>
                        <span className="heading">2</span>
                        <span className="description">Cerrado</span>
                      </div> */}
                    </div>
                  </div>
                </Row>
                {
                  listSupport.map((item, index) => (
                    <span onClick={(e) => {
                      // console.log("Teste: ", item.id_ticket)
                      funlistReply(item.id_ticket)
                      // contTickers()
                    }}>
                      <Card className="mb-2 bg-secondary shadow">
                        <CardBody>
                          <div className="text-center">
                            <div className="h5 mt-2 mb-2">
                              <i className="ni business_briefcase-24" />
                              {item.subject}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </span>
                  ))
                }
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="7">
            {
              formSupport()
            }
            {
              sendReplySuport()
            }
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Suport;
