// eslint-disable-next-line
import React, { useEffect, useState } from "react"
import {
  Card,
  Button,
  CardHeader,
  Table,
  Container,
  Row,
  Input
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import serveFunction from "serve/serve";

const Reports = () => {

  //const [domains, setDomains] = useState([]);
  const [itemFilter, setItemFilter] = useState({ id: 'Hoy' });
  const [customData, setCustomData] = useState({
    dataInitial: "",
    dataFinal: ""
  });
  const [isload, setIsLoad] = useState(true);
  const [dataReports, setDataReports] = useState({});
  const [valueReports, setValueReports] = useState(false);
  //const [stateTotalValue, setStateTotalValue] = useState({});
  const listfilter = ['Hoy', 'Personalizado'];

  const onchange = (e) => {
    const { value, name } = e.target
    setItemFilter({ ...itemFilter, [name]: value })
  }

  const onchangeData = (e) => {
    const { value, name } = e.target
    setCustomData({ ...customData, [name]: value })
  }

  async function getReports() {
    var dateCustom = new Date();

    var dia = String(dateCustom.getDate()).padStart(2, '0');
    var mes = String(dateCustom.getMonth() + 1).padStart(2, '0');
    var ano = dateCustom.getFullYear();
    dateCustom = dia + '-' + mes + '-' + ano;

    dateCustom = dateCustom.split('-').reverse().join('-')

    if (itemFilter.id === 'Hoy') {
      let dat = {
        dataInitial: dateCustom,
        dataFinal: dateCustom
      }
      let dataReports = await serveFunction.revShare(dat);
      let dataResponse = await dataReports;
      setDataReports(dataResponse);
      setValueReports(true);
    }

    if (itemFilter.id === 'Personalizado') {
      let dataReports = await serveFunction.revShare(customData);
      let dataResponse = await dataReports;
      setDataReports(dataResponse);
      setValueReports(true);
    }

  }

  useEffect(() => {
    async function call() {
      let res = await serveFunction.listDomains()
      //setDomains(res);
      console.log(res);
      setIsLoad(false)
    }
    call();
  }, [])

  if (isload) {
    return (<>
      <Container className="mt-5" fluid>
        {/* Table */}
        <Row className="row justify-content-md-center">
          <div className="col">
            <center>
              <div style={{ marginTop: 250, marginBottom: 300 }} className="spinner-grow text-primary" role="status">
                <span className="sr-only align-bottom">Loading...</span>
              </div>
            </center>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>);
  }

  function viewReports() {
    
    if (valueReports) {
      var listValues = [];
      for(let i in dataReports.data){
        // if(dataReports[i].domain == itemFilter.id_domain){
          listValues.push(dataReports.data[i]);
          console.log('indo....',dataReports.data[i]);
        // }
      }
     
    //   var total = listValues.reduce(getTotal, 0);
    //   function getTotal(total, item) {
    //     return total + (item.impressions);
    //   }
      
    //   var totalClicks = listValues.reduce(getTotalClicks, 0);
    //   function getTotalClicks(total, item) {
    //     return total + (item.clicks);
    //   }

    //   var totalViewAbility = listValues.reduce(getTotalViewAbility, 0);
    //   function getTotalViewAbility(total, item) {
    //     return total + (item.view_ability);
    //   }

    //   var totalEcpm = listValues.reduce(getTotalEcpm, 0);
    //   function getTotalEcpm(total, item) {
    //     return total + (item.ecpm);
    //   }
    //   totalEcpm = totalEcpm / listValues.length;

      var totalRevenue = listValues.reduce(getTotalRevenue, 0);
      function getTotalRevenue(total, item) {
        return total + (item.revenue);
      }
      
      var totalRavshare = listValues.reduce(getTotalRavshare, 0);
      function getTotalRavshare(total, item) {
        return total + (item.rev_daniel);
      }
      
      var totalImpressions = listValues.reduce(getTotalImpressions, 0);
      function getTotalImpressions(total, item) {
        return total + (item.impressions);
      }
      
      return (<>
        {
          dataReports.data.map((item) => (            
            <tr>            
              <td>{item.date}</td>
              <td>{item.impressions.toLocaleString('br')}</td>
              <td>$ {item.revenue.toFixed(2)}</td>
              <td>$ {item.rev_daniel.toFixed(2)}</td>              
            </tr>
          ))
        }
          <tr>            
              <td>Total</td>
              <td>{totalImpressions.toLocaleString('br')}</td>
              <td>$ {totalRevenue.toFixed(2)}</td>
              <td>$ {totalRavshare.toFixed(2)}</td>              
            </tr>
      </>);
    }
  }

  function customReports() {
    if (itemFilter.id === "Personalizado") {
      return (
        <>
          <Row>
            <div className="col-auto">
              <Input name="dataInitial" onChange={onchangeData} value={customData.dataInitial} type="date" />
            </div>
            <div className="col-auto">
              <Input name="dataFinal" onChange={onchangeData} value={customData.dataFinal} type="date" />
            </div>
          </Row>
        </>
      );
    }
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col-auto">
                    <h3 className="mb-0">Reportes</h3>
                  </div>
                  <div className="col-auto">
                    <Row>
                      
                      <div className="col-auto mb-2">
                        <select name="id" onChange={onchange} class="form-control">
                          {
                            listfilter.map((item) => (
                              <option value={item}>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className="col-auto">
                        {
                          customReports()
                        }
                      </div>
                      <div className="col-auto">
                        <Button color="info" type="button" onClick={() => {
                          getReports()
                        }}>
                          Búsqueda
                        </Button>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Impresiones</th>
                    <th scope="col">Ingresos (U$$)</th>
                    <th scope="col">Comision (U$$)</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {viewReports()}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>
  );
};

export default Reports;