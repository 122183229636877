import	React,{ useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row
} from "reactstrap";

// core components
//import Header from "components/Headers/Header.js";
import serveFunction from "serve/serve";

import HeaderAccount from "components/Headers/HeaderAccount.js";

const Invoicing = () => {

  const [listHistoryRevenue, setListHistoryRevenue] = useState({});
  const [isload, setIsLoad] = useState(true);
  //const [totalValue, setTotalValue] = useState({});

  useEffect(() => {
    async function call() {
      let res = await serveFunction.listHistory()
      setListHistoryRevenue(res);
      setIsLoad(false)
    }
    call();
  }, []);

  if (isload) {
    return (<>
      <Container className="mt-5" fluid>
        {/* Table */}
        <Row className="row justify-content-md-center">
          <div className="col">
            <center>
              <div style={{ marginTop: 250, marginBottom: 300 }} className="spinner-grow text-primary" role="status">
                <span className="sr-only align-bottom">Loading...</span>
              </div>
            </center>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>);
  }

  return (
    <>
      <HeaderAccount />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col-6">
                    <h3 className="mb-0">Extracto</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Data</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                {
                  listHistoryRevenue.reverse().map((item) => (
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {item.id_fin_movimentation}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{item.date_expiry}</td>
                      <td>U$ {item.value}</td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>
  );
};

export default Invoicing;





